
import { Component, Vue } from 'vue-property-decorator';
import HomeActions from '@/components/HomeActions.vue';
import AccountDetailsForm from '@/components/AccountDetailsForm.vue';

@Component({
  components: {
    HomeActions,
    AccountDetailsForm,
  },
})
export default class Home extends Vue {
  get businessDetailsCompleted(): boolean {
    return this.$store.getters['authModule/areBusinessDetailsCompleted'];
  }
}
