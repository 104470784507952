
import { Component, Vue } from 'vue-property-decorator';
import QRCodeReader from '@/components/QRCodeReader.vue';
import giftCardService from '@/services/giftCards/giftCardService';

@Component({
  components: { QRCodeReader },
})
export default class SignInForm extends Vue {
  public loading = false;
  public isScannerDisplayed = false;

  get permissionsToReloadCard(): string[] {
    const permissions = this.$store.getters['authModule/permissions'];
    if (permissions && permissions.actions && permissions.actions.includes('reloadCard')) {
      return ['reloadCard'];
    }
    return [];
  }

  async exportCardCredited(): Promise<void> {
    this.loading = true;
    try {
      const { data } = await giftCardService.exportGiftCardsCreditedToCsv();
      const blob = new Blob([data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'cartes-ckdo-creditees.csv';
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  async onDecode(decodedString: string | null): Promise<void> {
    this.loading = true;
    try {
      const { data } = await giftCardService.checkValidity(decodedString);
      this.$store.dispatch('cardModule/codeSuccess', { card: data });
      this.$router.push({
        name: 'CodeResult',
        query: { result: 'success', uuid: decodedString },
      });
    } catch (error: any) {
      this.$router.push({
        name: 'CodeResult',
        query: { result: 'error' },
      });
      const { data } = error.response;
      const message = Array.isArray(data.message) ? data.message[0] : data.message;
      this.$store.dispatch('cardModule/codeFailure', { error: message });
    } finally {
      this.loading = false;
    }
  }
}
