import { AxiosPromise } from 'axios';
import axiosInstance from '@/helpers/axios.config';

class CommuneService {
  protected get baseUrl(): string {
    return `${process.env.VUE_APP_API_URL}/communes/find/all-territories?page=1&limit=100`;
    // return `${process.env.VUE_APP_API_URL}/communes`;
  }

  getAll(): Promise<AxiosPromise> {
    return axiosInstance.get(this.baseUrl);
  }
}

export default new CommuneService();
