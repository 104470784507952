var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content p-4"},[(!_vm.isFormValidated)?_c('div',[_c('h1',{staticClass:"is-size-4 has-text-centered has-text-weight-light mt-0 mb-6"},[_vm._v(" Complétez votre compte ")]),_c('b-steps',{attrs:{"animated":true,"rounded":true,"icon-prev":"chevron-left","icon-next":"chevron-right","label-position":"bottom","mobile-mode":"compact","animateInitially":false,"type":"is-secondary","size":"is-large"},scopedSlots:_vm._u([{key:"navigation",fn:function({ next }){return [(!next.disabled)?_c('b-button',{attrs:{"label":"Suivant","type":"is-primary","icon-right":"chevron-right","expanded":"","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.goToNextStep(next)}}}):_c('b-button',{attrs:{"label":"Terminer","type":"is-primary","icon-left":"check","expanded":"","rounded":"","disabled":_vm.$v.$invalid},on:{"click":_vm.submit}})]}}],null,false,4002367116),model:{value:(_vm.activeStep),callback:function ($$v) {_vm.activeStep=$$v},expression:"activeStep"}},[_c('b-step-item',{attrs:{"step":"1","icon":"account","clickable":""}},[_c('b-field',{staticClass:"has-text-left has-text-weight-light",attrs:{"label":"Prénom du gérant","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.ownerFirstName.$error },"message":[
              {
                'Champs requis': !_vm.$v.form.ownerFirstName.required && _vm.$v.form.ownerFirstName.$dirty
              },
            ]}},[_c('b-input',{attrs:{"rounded":"","placeholder":"Prénom du gérant"},model:{value:(_vm.$v.form.ownerFirstName.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.ownerFirstName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.ownerFirstName.$model"}})],1),_c('b-field',{staticClass:"has-text-left has-text-weight-light",attrs:{"label":"Nom du gérant","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.ownerLastName.$error },"message":[
              { 'Champs requis': !_vm.$v.form.ownerLastName.required && _vm.$v.form.ownerLastName.$dirty },
            ]}},[_c('b-input',{attrs:{"rounded":"","placeholder":"Nom du gérant"},model:{value:(_vm.$v.form.ownerLastName.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.ownerLastName, "$model", $$v)},expression:"$v.form.ownerLastName.$model"}})],1),_c('b-field',{staticClass:"has-text-left has-text-weight-light",attrs:{"label":"Numéro de téléphone","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.phoneNumber.$error },"message":[
              { 'Champs requis': !_vm.$v.form.phoneNumber.required && _vm.$v.form.phoneNumber.$dirty },
              { 'Veuillez renseigner un numéro valide':
                  !_vm.$v.form.phoneNumber.isPhoneNumber && _vm.$v.form.phoneNumber.$dirty },
            ]}},[_c('b-input',{attrs:{"rounded":"","native-type":"tel","placeholder":"Numéro de téléphone"},model:{value:(_vm.formatedPhoneNumber),callback:function ($$v) {_vm.formatedPhoneNumber=$$v},expression:"formatedPhoneNumber"}})],1),_c('b-field',{staticClass:"has-text-left has-text-weight-light",attrs:{"label":"Nom du commerce","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.tradeName.$error },"message":[
              { 'Champs requis': !_vm.$v.form.tradeName.required && _vm.$v.form.tradeName.$dirty },
            ]}},[_c('b-input',{attrs:{"rounded":"","placeholder":"Nom du commerce"},model:{value:(_vm.$v.form.tradeName.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.tradeName, "$model", $$v)},expression:"$v.form.tradeName.$model"}})],1),_c('b-field',{attrs:{"label":"Ma categorie de commerce","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.category.$error },"message":[
              { 'Champs requis': !_vm.$v.form.category.required && _vm.$v.form.category.$dirty },
            ]}},[_c('b-select',{attrs:{"rounded":"","placeholder":"Ma categorie de commerce","required":"","expanded":""},model:{value:(_vm.$v.form.category.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.category, "$model", $$v)},expression:"$v.form.category.$model"}},_vm._l((_vm.categories),function(cat){return _c('option',{key:cat._id,domProps:{"value":cat._id}},[_vm._v(" "+_vm._s(`${cat.category}`)+" ")])}),0)],1),_c('b-field',{attrs:{"label":"Ma commune CKDO","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.commune.$error },"message":[
              { 'Champs requis': !_vm.$v.form.commune.required && _vm.$v.form.commune.$dirty },
              { 'Commune inconnue': !_vm.$v.form.commune.isMongoId && _vm.$v.form.commune.$dirty },
            ]}},[_c('b-select',{attrs:{"rounded":"","placeholder":"Ma commune CKDO","required":"","loading":_vm.isLoadingCommunes,"expanded":""},model:{value:(_vm.$v.form.commune.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.commune, "$model", $$v)},expression:"$v.form.commune.$model"}},_vm._l((_vm.communes),function(c){return _c('option',{key:c._id,domProps:{"value":c._id}},[_vm._v(" "+_vm._s(`${c.name}`)+" ")])}),0)],1)],1),_c('b-step-item',{attrs:{"step":"2","icon":"store","clickable":""}},[_c('b-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showRna),expression:"!showRna"}],staticClass:"has-text-left has-text-weight-light",attrs:{"label":"SIRET Entreprise (14 chiffres)","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.siret.$error },"message":[
              { 'Champs requis': !_vm.$v.form.siret.required && _vm.$v.form.siret.$dirty },
              {
                'Veuillez renseigner un SIRET valide':
                  !_vm.$v.form.siret.isSiret && _vm.$v.form.siret.$dirty,
              },
            ]}},[_c('b-input',{ref:"siretRef",attrs:{"rounded":"","placeholder":"Tapez votre SIRET","expanded":"","type":"search"},on:{"input":_vm.searchSiret},model:{value:(_vm.$v.form.siret.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.siret, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.siret.$model"}}),_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"rounded":"","label":"Chercher","type":"is-primary","loading":_vm.isFetchingSiret},on:{"click":() => _vm.searchSiret(_vm.$refs.siretRef.value)}})],1)],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showRna),expression:"!showRna"}],staticStyle:{"margin":"5px"},attrs:{"size":"is-small","rounded":"","type":"is-light","label":"Je suis une association sans SIRET, je saisis mon RNA"},on:{"click":function($event){return _vm.toggleRnaField()}}}),_c('b-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRna),expression:"showRna"}],staticClass:"has-text-left has-text-weight-light",attrs:{"label":"RNA Association (9 caractères)","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.rna.$error },"message":[
              { 'Champs requis': !_vm.$v.form.rna.required && _vm.$v.form.rna.$dirty },
              {
                'Veuillez renseigner un RNA valide':
                  !_vm.$v.form.rna.isRna && _vm.$v.form.rna.$dirty,
              },
            ]}},[_c('b-input',{ref:"rnaRef",attrs:{"rounded":"","placeholder":"Je saisis mon RNA","expanded":"","type":"search"},on:{"input":_vm.searchRna},model:{value:(_vm.$v.form.rna.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.rna, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.rna.$model"}}),_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"rounded":"","label":"Chercher","type":"is-primary","loading":_vm.isFetchingSiret},on:{"click":() => _vm.searchRna(_vm.$refs.rnaRef.value)}})],1)],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRna),expression:"showRna"}],staticStyle:{"margin":"5px"},attrs:{"rounded":"","size":"is-small","type":"is-light","label":"Je suis une entreprise avec SIRET"},on:{"click":function($event){return _vm.toggleRnaField()}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSiretFound),expression:"isSiretFound"}]},[_c('b-field',{staticClass:"has-text-left has-text-weight-light",attrs:{"label":"Raison sociale","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.businessName.$error },"message":[
                { 'Champs requis': !_vm.$v.form.businessName.required && _vm.$v.form.businessName.$dirty },
              ]}},[_c('b-input',{attrs:{"rounded":"","placeholder":"Raison sociale"},model:{value:(_vm.$v.form.businessName.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.businessName, "$model", $$v)},expression:"$v.form.businessName.$model"}})],1),_c('b-field',{staticClass:"has-text-left has-text-weight-light",attrs:{"label":"Adresse","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.address.street.$error },"message":[
                {
                  'Champs requis':
                    !_vm.$v.form.address.street.required && _vm.$v.form.address.street.$dirty,
                },
              ]}},[_c('b-input',{attrs:{"rounded":"","placeholder":"Adresse"},model:{value:(_vm.$v.form.address.street.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.address.street, "$model", $$v)},expression:"$v.form.address.street.$model"}})],1),_c('b-field',{staticClass:"has-text-left has-text-weight-light",attrs:{"label":"Code postal","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.address.postalCode.$error },"message":[
                {
                  'Champs requis':
                    !_vm.$v.form.address.postalCode.required && _vm.$v.form.address.postalCode.$dirty
                },
                {
                  'Veuillez renseigner un code postal valide':
                    !_vm.$v.form.address.postalCode.isPostalCode && _vm.$v.form.address.postalCode.$dirty
                },
              ]}},[_c('b-input',{attrs:{"rounded":"","placeholder":"Code postal"},model:{value:(_vm.$v.form.address.postalCode.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.address.postalCode, "$model", $$v)},expression:"$v.form.address.postalCode.$model"}})],1),_c('b-field',{staticClass:"has-text-left has-text-weight-light",attrs:{"label":"Ville","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.address.city.$error },"message":[
                { 'Champs requis': !_vm.$v.form.address.city.required && _vm.$v.form.address.city.$dirty },
              ]}},[_c('b-input',{attrs:{"rounded":"","placeholder":"Ville"},model:{value:(_vm.$v.form.address.city.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.address.city, "$model", $$v)},expression:"$v.form.address.city.$model"}})],1)],1)],1),_c('b-step-item',{attrs:{"step":"3","icon":"piggy-bank","clickable":""}},[_c('b-field',{staticClass:"has-text-left has-text-weight-light",attrs:{"label":"IBAN","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.bankDetails.iban.$error },"message":[
              {
                'Champs requis':
                  !_vm.$v.form.bankDetails.iban.required && _vm.$v.form.bankDetails.iban.$dirty
              },
              {
                'Veuillez renseigner un IBAN valide':
                  !_vm.$v.form.bankDetails.iban.isIBAN && _vm.$v.form.bankDetails.iban.$dirty
              },
            ]}},[_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:(['XXXX XXXX XXXX XXXX XXXX XXXX XXXX']),expression:"['XXXX XXXX XXXX XXXX XXXX XXXX XXXX']"}],attrs:{"rounded":"","placeholder":"IBAN"},model:{value:(_vm.form.bankDetails.iban),callback:function ($$v) {_vm.$set(_vm.form.bankDetails, "iban", $$v)},expression:"form.bankDetails.iban"}})],1),_c('b-field',{staticClass:"has-text-left has-text-weight-light",attrs:{"label":"BIC","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.bankDetails.bic.$error },"message":[
              {
                'Champs requis': !_vm.$v.form.bankDetails.bic.required && _vm.$v.form.bankDetails.bic.$dirty
              },
              {
                'Veuillez renseigner un BIC valide':
                  !_vm.$v.form.bankDetails.bic.isBIC && _vm.$v.form.bankDetails.bic.$dirty
              },
            ]}},[_c('b-input',{attrs:{"rounded":"","placeholder":"BIC"},model:{value:(_vm.$v.form.bankDetails.bic.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.bankDetails.bic, "$model", $$v)},expression:"$v.form.bankDetails.bic.$model"}})],1)],1),_c('b-step-item',{attrs:{"step":"4","icon":"list-status","clickable":""}},[_c('b-field',{staticClass:"mt-5 has-text-left has-text-weight-light",attrs:{"custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.cguOne.$error },"message":[
              {
                'Champs requis':
                  !_vm.$v.form.cguOne.required && _vm.$v.form.cguOne.$dirty
              },
            ]}},[_c('b-checkbox',{attrs:{"type":"is-primary"},model:{value:(_vm.$v.form.cguOne.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.cguOne, "$model", $$v)},expression:"$v.form.cguOne.$model"}},[_vm._v(" Je reconnais avoir pris connaissance et accepter les "),_c('a',{attrs:{"href":"/politique-de-confidentialite","target":"_blank"}},[_c('b',[_vm._v(" conditions générales d'utilisation")])]),_vm._v(" du service ")])],1),_c('b-field',{staticClass:"mt-5 has-text-left has-text-weight-light",attrs:{"custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.cguThree.$error },"message":[
              {
                'Champs requis':
                  !_vm.$v.form.cguThree.required && _vm.$v.form.cguThree.$dirty
              },
            ]}},[_c('b-checkbox',{attrs:{"type":"is-primary"},model:{value:(_vm.$v.form.cguThree.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.cguThree, "$model", $$v)},expression:"$v.form.cguThree.$model"}},[_vm._v(" J'ai noté que je dispose d'un droit d'accès, de modification, de suppression de mes données en contactant Proxity à l'adresse "),_c('b',[_vm._v("administration@proxity-edf.com")]),_vm._v(". ")])],1)],1)],1)],1):_c('div',[_c('h1',{staticClass:"is-size-4 has-text-centered has-text-weight-light mt-0 mb-6"},[_vm._v(" Compte complété ! ")]),_c('SuccessCheck2',{attrs:{"size":"150px"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }