
import { Component, Vue } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import validator from 'validator';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';
import IBAN from 'iban';
import SuccessCheck2 from '@/components/SuccessCheck2.vue';
import communeService from '@/services/communes/communeService';
import authService from '@/services/auth/authService';
import businessService from '@/services/business/businessService';
import categoryService from '@/services/categories/categoryService';

@Component({
  components: {
    SuccessCheck2,
  },
})
export default class AccountDetailsForm extends Vue {
  public error = '';
  public activeStep = 0;
  public showRna = false;
  public isFetchingSiret = false;
  public communes = [];
  public categories = [];
  public isLoadingCommunes = false;
  public isLoading = false;
  public isSiretFound = false;
  public isFormValidated = false;
  public form: any = {
    ownerFirstName: '',
    ownerLastName: '',
    tradeName: '',
    businessName: '',
    siret: '',
    rna: '',
    phoneNumber: '',
    address: {
      houseNumber: '',
      street: '',
      postalCode: '',
      city: '',
    },
    bankDetails: {
      iban: '',
      bic: '',
    },
    category: null,
    commune: null,
    cguOne: false,
    // cguTwo: false,
    cguThree: false,
  };

  $refs!: {
    siretRef: HTMLInputElement,
    rnaRef: HTMLInputElement,
  }

  validations(): any {
    return {
      form: {
        ownerFirstName: {
          required,
        },
        ownerLastName: {
          required,
        },
        tradeName: {
          required,
        },
        siret: {
          // required,
          isSiret: (value: string): boolean => {
            const size = 14;
            // Il faut soit un RNA soit un SIRET
            if (!value) return !!this.form.rna;
            if (Number.isNaN(value) || value.length !== size) return false;

            let bal = 0;
            let total = 0;

            for (let i = size - 1; i >= 0; i -= 1) {
              const step = (value.charCodeAt(i) - 48) * (bal + 1);

              total += step > 9 ? step - 9 : step;
              bal = 1 - bal;
            }
            return (total % 10) === 0;
          },
        },
        rna: {
          // required,
          isRna: (value: string): boolean => {
            // Commence par W + 9 caractères numériques
            const size = 10;
            // Il faut soit un RNA soit un SIRET
            if (!value) return !!this.form.siret;
            if (value.trim().length !== size) return false;
            if (value[0] !== 'W') return false;
            const rnaNumeric = value.trim().substring(1);
            if (Number.isNaN(rnaNumeric)) return false;
            return true;
          },
        },
        businessName: { required },
        phoneNumber: {
          required,
          isPhoneNumber: (value: string): boolean => {
            const phoneNumber = parsePhoneNumber(value, 'FR');
            const isValid = Boolean(phoneNumber && phoneNumber.isValid());

            return isValid;
          },
        },
        address: {
          street: { required },
          postalCode: {
            required,
            isPostalCode: (value: string): boolean => Boolean(value && validator.isPostalCode(value, 'FR')),
          },
          city: { required },
        },
        bankDetails: {
          iban: {
            required,
            isIBAN: (value: string): boolean => IBAN.isValid(value),
          },
          bic: {
            required,
            isBIC: (value: string): boolean => Boolean(value && validator.isBIC(value)),
          },
        },
        commune: {
          required,
          isMongoId: (value: string): boolean => Boolean(value && validator.isMongoId(value)),
        },
        category: {
          required,
          // eslint-disable-next-line
          isIn: (value: string): boolean => Boolean(value && validator.isMongoId(value) && this.categories.some((category: { _id: string }) => category._id === value)),
        },
        cguOne: {
          required,
          checked: (value: string): boolean => Boolean(value),
        },
        // cguTwo: {
        //   required,
        //   checked: (value: string): boolean => Boolean(value),
        // },
        cguThree: {
          required,
          checked: (value: string): boolean => Boolean(value),
        },
      },
    };
  }

  created(): void {
    this.getCommunes();
    this.getCategories();
  }

  toggleRnaField(): any {
    this.showRna = !this.showRna;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  goToNextStep(next: any): void{
    if (this.activeStep === 0) {
      // check step 0 validity
      console.log('is step one valid', this.isStepOneValid());
      if (this.isStepOneValid()) {
        next.action();
      }
    }

    if (this.activeStep === 1) {
      console.log('is step two valid', this.isStepTwoValid());
      // check step 1 validity
      if (this.isStepTwoValid()) {
        next.action();
      }
    }

    if (this.activeStep === 2) {
      console.log('is step three valid', this.isStepThreeValid());
      // check step 2 validity
      if (this.isStepThreeValid()) {
        next.action();
      }
    }
  }

  isStepOneValid(): boolean {
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.ownerFirstName?.$touch();
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.ownerLastName?.$touch();
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.tradeName?.$touch();
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.category?.$touch();
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.phoneNumber?.$touch();
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.commune?.$touch();

    return Boolean(!this.$v?.form?.ownerFirstName?.$error)
           && Boolean(!this.$v?.form?.ownerLastName?.$error)
           && Boolean(!this.$v?.form?.phoneNumber?.$error)
           && Boolean(!this.$v?.form?.commune?.$error);
  }

  isStepTwoValid(): boolean {
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.siret?.$touch();
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.rna?.$touch();
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.businessName?.$touch();
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.adress?.street?.$touch();
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.adress?.postalCode?.$touch();
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.adress?.city?.$touch();

    return Boolean(!this.$v?.form?.businessName?.$error)
           && Boolean(!this.$v?.form?.siret?.$error)
           && Boolean(!this.$v?.form?.rna?.$error)
           && Boolean(!this.$v?.form?.adress?.street?.$error)
           && Boolean(!this.$v?.form?.adress?.postalCode?.$error)
           && Boolean(!this.$v?.form?.adress?.city?.$error);
  }

  isStepThreeValid(): boolean {
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.bankDetails?.iban?.$touch();
    // eslint-disable-next-line no-unused-expressions
    this.$v?.form?.bankDetails?.bic?.$touch();

    return Boolean(!this.$v?.form?.bankDetails?.iban?.$error)
           && Boolean(!this.$v?.form?.bankDetails?.bic?.$error);
  }

  async submit(): Promise<void> {
    if (!this.$v.$invalid) {
      this.isLoading = true;
      try {
        const {
          ...payload
        } = this.form;
        const userId = this.$store.getters['authModule/userId'];
        const { data } = await authService.verifyBusinessDetails(
          userId,
          {
            businessDetails: payload,
            category: payload.category,
          },
        );

        this.isFormValidated = true;
        setTimeout(() => {
          this.$store.dispatch('authModule/signin', {
            token: data.access_token,
          });
        }, 2000);
      } catch (error) {
        if (error?.response?.status === 400 && error?.response?.data?.message) {
          this.$buefy.snackbar.open({
            message: error?.response?.data?.message,
            position: 'is-bottom-right',
            type: 'is-danger',
            indefinite: false,
            actionText: 'ok',
          });
        } else {
          console.log(error);
        }
      } finally {
        this.isLoading = false;
      }
    }
  }

  get formatedPhoneNumber(): string {
    return this.$v.form.phoneNumber?.$model;
  }

  set formatedPhoneNumber(phone: string) {
    const parsePN = parsePhoneNumber(phone, 'FR') as any;

    if (this.$v.form.phoneNumber && parsePN) {
      const formatedPhone = new AsYouType('FR').input(parsePN.number);
      this.$v.form.phoneNumber.$model = formatedPhone;
    }
  }

  async getCommunes(): Promise<void> {
    try {
      this.isLoadingCommunes = true;
      const { data } = await communeService.getAll();
      this.communes = data.docs;
    } catch (error) {
      console.log('An error occured: get all communes');
    } finally {
      this.isLoadingCommunes = false;
    }
  }

  async getCategories(): Promise<void> {
    const { data } = await categoryService.getAll();
    this.categories = data;
  }

  async searchSiret(value: string): Promise<void> {
    if (this.$v.form.siret?.$error && this.$v.form.siret?.$dirty) {
      this.isSiretFound = false;
      this.form.address = {
        houseNumber: '',
        street: '',
        postalCode: '',
        city: '',
      };
      this.form.businessName = '';
      return;
    }
    this.isFetchingSiret = true;

    try {
      const dataFromApi = await businessService.getBySiret(value);
      if (+dataFromApi.status === 200 && !!dataFromApi.data) {
        this.form.address = dataFromApi.data.address;
        this.form.businessName = dataFromApi.data.businessName;
        this.form.rna = '';
        this.isSiretFound = true;
      } else {
        console.log({ dataFromApi });
        this.isSiretFound = false;
      }
    } catch (error) {
      this.isSiretFound = false;
      throw error;
    } finally {
      this.isFetchingSiret = false;
    }
  }

  async searchRna(value: string): Promise<void> {
    if (this.$v.form.rna?.$error && this.$v.form.rna?.$dirty) {
      this.isSiretFound = false;
      this.form.address = {
        houseNumber: '',
        street: '',
        postalCode: '',
        city: '',
      };
      this.form.businessName = '';
      return;
    }
    this.isFetchingSiret = true;

    try {
      const dataFromApi = await businessService.getByRna(value);
      if (+dataFromApi.status === 200 && !!dataFromApi.data) {
        this.form.address = dataFromApi.data.address;
        this.form.businessName = dataFromApi.data.businessName;
        this.isSiretFound = true;
        this.form.siret = '';
      } else {
        console.log({ dataFromApi });
        this.isSiretFound = false;
      }
    } catch (error) {
      this.isSiretFound = false;
      throw error;
    } finally {
      this.isFetchingSiret = false;
    }
  }
}
